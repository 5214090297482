import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowDownIcon, CheckIcon, PhoneIcon } from '@heroicons/react/solid';
import PhotoGallery from '../../components/PhotoGallery';
import ContactUs from '../../components/ContactUs';
import SEO from '../../components/SEO';

export default function TournamentsPage() {
  const data = useStaticQuery(graphql`
  query {
    splash: file(relativePath: { eq: "tournament-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    fighting: file(relativePath: { eq: "fighting-stance.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    gallery: sanityGallery(name: {eq: "Tournaments"}) {
      photos {
        id
        image {
          asset {
            gatsbyImageData( placeholder: BLURRED)
          }
        }
        name
        order
        tags {
          title
        }
        caption
      }
      name
      caption
    }
  }
`);
console.log(data.splash)
  return (
    <>
      <SEO
        title="Tournament"
        description="Challenge your limits with a little friendly competition and compete alongside
              your peers! Yuan Yen Do Tournaments are a great opportunity for all ranks to put
              their skills to the test and apply their knowledge from class."
        image={data.splash}
      />
      <section className="relative z-20 xl:container xl:mx-auto">
        <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Challenge your </span>
              {' '}
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600 xl:inline">body and mind</span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Challenge your limits with a little friendly competition and compete alongside
              your peers! Yuan Yen Do Tournaments are a great opportunity for all ranks to put
              their skills to the test and apply their knowledge from class.
            </p>
            <div className="mt-10 sm:flex sm:justify-center">
              <div className="rounded-md shadow">
                <AnchorLink
                  to="/events/tournaments#tournament-info"
                  title="Tournament Info"
                  className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
                >
                  <ArrowDownIcon className="w-5 h-5 pr-2" />
                  Learn More
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <GatsbyImage
            className="absolute inset-0 w-full h-full filter drop-shadow-lg"
            imgClassName="object-contain"
            image={data.splash.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do students with trophies"
          />
        </div>
      </section>
      <section className="relative z-0 px-4 py-12 bg-right bg-cover shadow-inner filter md:px-16 bg-lines md:py-16 xl:py-24" id="tournament-info">
        <div className="container grid grid-cols-6 mx-auto justify-items-end">
          <div className="hidden md:block md:col-span-2">

            <div className="relative z-0 w-64 h-full">
              <GatsbyImage
                className="w-full h-full"
                image={data.fighting.childImageSharp.gatsbyImageData}
                alt="Yuan Yen Do Kids with Trophies"
              />
            </div>
          </div>
          <div className="col-span-6 text-center text-white md:col-span-4 md:text-left">
            <h3 className="mb-2 text-3xl font-black tracking-tight sm:text-3xl lg:text-4xl xl:text-5xl filter drop-shadow-lg text-secondary">
              Yuan Yen Do Self Defense Tournaments
            </h3>
            <p className="pl-4 text-xl lg:text-2xl xl:text-3xl">Sign up for your next challenge</p>
            <ul className="my-4 md:ml-12 lg:text-lg xl:text-xl">
              <li className="flex">
                <CheckIcon className="w-6 h-6 mr-4 text-green-500" aria-hidden="true" />
                Reinforces Focus and Self-Control
              </li>
              <li className="flex">
                <CheckIcon className="w-6 h-6 mr-4 text-green-500" aria-hidden="true" />
                Positive Milestone
              </li>
              <li className="flex">
                <CheckIcon className="w-6 h-6 mr-4 text-green-500" aria-hidden="true" />
                Bring the Whole Family!
              </li>
            </ul>
            <div className="max-w-sm mt-10 md:ml-16">
              <AnchorLink
                to="/events/tournaments#contact-us"
                className="flex items-center justify-center py-3 pl-2 pr-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
              >
                <PhoneIcon className="w-6 h-6 pr-2" />
                Contact Us
              </AnchorLink>
            </div>
            <p className="mt-8 lg:text-lg xl:text-xl">Our Cohasset and Pembroke members get the opportunity twice a year to compete in the Team Division, Sparring, and Bo Staff Fighting Divisions in point-scored rounds! Check out our Facebook for pictures of past tournaments and talk to your center leader about getting signed up for the next tournament!</p>
          </div>
        </div>
      </section>

      <ContactUs />

      <PhotoGallery title="Yuan Yen Do Tournaments" caption="Take a sneak peak on whats in store during our tournaments" gallery={data.gallery} />
    </>
  );
}
